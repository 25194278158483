import React, { useState, useEffect } from "react";

import "./style.css";
import Hand from "../../images/Untitled design (20) 1.png";
import Ring from "../../images/Group 1000004381.png";
import Ring1 from "../../images/Group 25.png";
import Plane from "../../images/Group 1000000968.png";
import Country6 from "../../images/SVG.png";
import Country9 from "../../images/SVG (12).png";
import Arrow from "../../images/span.jss107.png";
import Arrow1 from "../../images/div (1).png";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { message, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    purpose: "Bachelors",
  },
  {
    id: 2,
    purpose: "Masters",
  },
];
function ScholarshipsOne() {
  const navigate = useNavigate();

  const [purposes, setpurposes] = useState(null);

  const handleSmallDivCountryClick = (id) => {
    const purposesData = data.find((item) => item.id === id);
    sessionStorage.setItem("purposes", purposesData.purpose);
    setpurposes(purposesData);
  };

  const handleNextButtonClick = () => {
    if (purposes) {
      console.log("Selected purposes:", purposes);

      setpurposes(null); // Clear the selected country after storing it in local storage
    } else {
      // Show a message indicating that a country needs to be selected
      alert("Please select a country before proceeding to the next step.");
    }
  };
  return (
    <div className="whole-div-findd">
      <Link to="/">
        <img className="logoacc" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <div>
        <Row justify="center">
          <Col xxl={12} xl={12} lg={12} md={10}>
            <div className="center-find">
              <div>
                <img src={Ring} alt="" className="ring-img" />
                <h1 className="finetext-new">
                  “One step closer to <br /> Finding the best
                  <br /> <a className="finetext-new1">Scholarships</a> for
                  <br /> you”
                </h1>
              </div>
            </div>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={10}>
            <div className="center-find12">
              <div>
                <img src={Ring1} alt="" className="ring1-div" />
                <img src={Hand} alt="" className="div-img-hand22" />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <di>
          <img src={Plane} alt="" className="plane-img" />
        </di>
        <br />
        <br />
        <div className="center-token-div-whole">
          <div className="bg-token-div">
            <div className="div-text-div">
              <img
                src={Arrow1}
                alt=""
                className="im-size12"
                onClick={() => navigate(-1)}
              />
              <p className="where-text">what degree do you want to pursue?</p>
            </div>
            <br />
            <div className="div-btn-both">
              {data.map((item) => (
                <div
                  className={`small-div-country ${
                    purposes && purposes.id === item.id
                      ? "border-highlight"
                      : ""
                  }`}
                  onClick={() => handleSmallDivCountryClick(item.id)}
                >
                  <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />{" "}
                  {item.purpose}
                </div>
              ))}
            </div>
            <br />
            <Link to="/scholarshipsTwo" style={{ textDecoration: "none" }}>
              <button
                className={`btn-next ${purposes ? "" : "btn-next:disabled"}`}
                disabled={!purposes}
                onClick={handleNextButtonClick}
              >
                Next <img src={Arrow} alt="" className="im-size" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScholarshipsOne;

import React from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import { Button, Col, Row } from "antd";

function ThreeCard() {
  return (
    <div>
      <Container>
        <div className="firstthree">
          <Row className="rowcntr">
            <Col>
              <div>
                <p className="frsttextu">Our Value</p>
                <p className="firscnd">
                  Building a future of excellence through <br />
                  transformative education and
                  <br /> unwavering support.
                  <br />
                  Empowering growth through
                  <br />
                  transformative education
                </p>
              </div>
              <div className="baadcen">
                <button className="lorembutt">
                  See All <img alt="abc" src="../images/right.svg" />
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <img
                  className="imgsi"
                  alt="abc"
                  src="../images/aboutbig4.png"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="firstthree">
          <Row className="rowcntr">
            <Col>
              <div>
                <img
                  className="imgsi"
                  alt="abc"
                  src="../images/aboutbig5.png"
                />
              </div>
            </Col>
            <Col>
              <div>
                <p className="frsttextui">Our Mission</p>
                <p className="firscnd">
                  Building a future of excellence through <br />
                  transformative education and <br />
                  unwavering support.
                  <br />
                  Empowering growth through
                  <br />
                  transformative education
                </p>
              </div>
              <div className="baadcen">
                <button className="lorembutt">
                  See All <img alt="abc" src="../images/right.svg" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="firstthree">
          <Row className="rowcntr">
            <Col>
              <div>
                <p className="frsttextuii">Our Achievement</p>
                <p className="firscnd">
                  Celebrating a legacy of empowering <br />
                  10,000+ graduates
                  <br />
                  worldwide. Our achievements
                  <br /> reflect our commitment to
                  <br />
                  fostering global leaders.
                </p>
              </div>
              <div className="baadcen">
                <button className="lorembutt">
                  See All <img alt="abc" src="../images/right.svg" />
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <img
                  className="imgsi"
                  alt="abc"
                  src="../images/aboutbig6.png"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default ThreeCard;

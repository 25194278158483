import React from "react";
import { Button, Col, Row } from "antd";
import "./index.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footermain">
      <Row className="rows">
        <Col>
          <div>
            <img
              className="imgfo"
              alt="abc"
              src="https://www.thestudenthelpline.co.in/assets/img/logo-footer.webp"
            />
            <br /> <br />
            <a
              href="https://learnwithfraternity.com/"
              style={{ textDecoration: "none", color: "white" }}
            >
              help@learnwithfraternity.com
            </a>
          </div>
        </Col>
        <Col>
          <Link to="/aboutus" style={{ textDecoration: "none" }}>
            <p className="scnddv">About Us</p>
          </Link>
          <div className="spacesless">
            <p>Portfolio</p>
            <p>Careers</p>
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p>Contact Us</p>
            </Link>
          </div>
        </Col>
        <Col>
          {" "}
          <div>
            <p>Contact us</p>
            <p>
              India
              <br /> Learn With Fraternity Pvt. Ltd. D-62, Noida, Sector 2
            </p>
            <p>919318325049</p>
          </div>
        </Col>
        <Col>
          <div className="fourpicsdiv">
            <a href="https://www.facebook.com/TheStudentHelpline/">
              {" "}
              <Button className="imagesdiv">
                <img alt="abc" src="../images/fb.png" />
              </Button>
            </a>
            <a href="https://www.instagram.com/thestudenthelplineofficial/">
              <Button className="imagesdiv">
                <img alt="abc" src="../images/insta.png" />
              </Button>
            </a>
            <a href="https://twitter.com/thestudent_help">
              {" "}
              <Button className="imagesdiv">
                <img alt="abc" src="../images/twitter.png" />
              </Button>
            </a>

            <a href="https://au.linkedin.com/company/the-student-helpline">
              <Button className="imagesdiv">
                <img alt="abc" src="../images/linkdin.png" />
              </Button>
            </a>
          </div>
        </Col>
      </Row>
      <br /> <br />
      <p className="allrights">
        © 2023 Learn With Fraternity. ALL RIGHTS RESERVED.
      </p>
    </div>
  );
}

export default Footer;

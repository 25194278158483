import { Col, Input, Row, Form, Select } from "antd";
import React from "react";
import "./style.css";
import Hand from "../../images/Untitled design (21) 1.png";
import Ring from "../../images/Group 1000004381.png";
import Ring1 from "../../images/Group 25.png";
import Plane from "../../images/Group 1000000968.png";
import Arrow from "../../images/span.jss107.png";
import Country from "../../images/image 18 (1).png";
import { Link } from "react-router-dom";
const { Option } = Select;
function AccommodationThree() {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 120,
          background: "white",
          borderRadius: ".5rem",
        }}
      >
        <Option value="91">
          {" "}
          <img src={Country} alt="" />
          +91
        </Option>
        <Option value="92">
          {" "}
          <img src={Country} alt="" />
          +92
        </Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="whole-div-findd">
      <Link to="/">
        <img className="logoacc" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <div>
        <Row justify="center">
          <Col xxl={12} xl={12} lg={12} md={10}>
            <div className="center-find">
              <div>
                <img src={Ring} alt="" className="ring-img" />
                <h1 className="finetext-new">
                  “Find your <br /> Accommodation <br /> near{" "}
                  <a className="finetext-new1">Top Universities</a> <br />{" "}
                  across the Globe”
                </h1>
              </div>
            </div>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={10}>
            <div className="center-find12">
              <div>
                <img src={Ring1} alt="" className="ring1-div" />
                <img src={Hand} alt="" className="div-img-hand" />
              </div>
            </div>
          </Col>
        </Row>
        <di>
          <img src={Plane} alt="" className="plane-img" />
        </di>
        <br />
        <br />
        <div className="center-token-div-whole">
          <div className="bg-token-div">
            <div className="div-text-div">
              <p className="where-text">Let's get started</p>
            </div>
            <div>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input addonBefore={prefixSelector} className="input-div" />
              </Form.Item>
            </div>
            <Link to="/finderOne" style={{ textDecoration: "none" }}>
              <button className="btn-next12 ">
                Continue <img src={Arrow} alt="" className="im-size" />
              </button>
            </Link>
            <p className="text-by-term">
              By continuing, you agree to our{" "}
              <a className="text-by-term1">Terms of Service & Privacy policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationThree;

import React from "react";
import CollapsibleExample from "../../Components/navbar";
import "./style.css";
import Footer from "../../Components/footer";
import HeroSectionBlog from "../../Components/heroSectionBlog";
import BlogMain from "../../Components/blogmain";
function BlogPage() {
  return (
    <div>
      <div className="bg-clr">
        <CollapsibleExample />
        <HeroSectionBlog />
        <BlogMain />
        <Footer />
      </div>
    </div>
  );
}

export default BlogPage;

import React from "react";
import "./style.css";
import { Button, Col, Row } from "antd";

function OurHistory() {
  return (
    <div>
      <div className="histmain">
        <p className="abietxt">Our History</p>
        <Row className="histrow">
          <Col>
            {" "}
            <p className="thirtyfive">
              35,000+ students joint
              <br />
              with us to achieve goal
            </p>{" "}
            <p className="histseclor">
              Join our thriving community of 35,000+ students and embark on a
              <br />
              journey of success together. Achieve your goals with our proven
              <br />
              track record of empowering students worldwide
            </p>
            <div className="baadcen">
              <button className="lorembutt">
                Join Us <img alt="abc" src="../images/right.svg" />
              </button>
            </div>
          </Col>
          <Col className="colhis">
            <img
              className="iskab"
              alt="abc"
              src="../images/terhaarrow.svg"
            ></img>
            <img
              className="imgsiez"
              alt="abc"
              src="../images/aboutbig3.png"
            />
            <img
              className="iskalag"
              alt="abc"
              src="../images/terhasecond.svg"
            ></img>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default OurHistory;

import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../Screen/LandingPage/index";
import BlogPage from "../Screen/BlogPage";
import ArticleMain from "../Screen/Articlemain";
import Finder from "../Components/finder";
import FinderOne from "../Components/finderOne";
import FinderTwo from "../Components/finderTwo";
import FinderThree from "../Components/finderThree";
import Ielts from "../Screen/Ielts";
import FinderFour from "../Components/finderFour";
import FinderFive from "../Components/finderFive";
import FinderSix from "../Components/finderSix";
import FinderSeven from "../Components/finderSeven";
import FinderEight from "../Components/finderEight";
import AboutUs from "../Screen/aboutus";
import Accommodation from "../Components/accommodation";
import AccommodationOne from "../Components/AccommodationOne";
import AccommodationTwo from "../Components/AccommodationTwo";
import AccommodationThree from "../Components/AccommodationThree";
import Scholarships from "../Components/Scholarships";
import ScholarshipsOne from "../Components/ScholarshipsOne";
import ScholarshipsTwo from "../Components/ScholarshipsTwo";
import ScholarshipsThree from "../Components/ScholarshipsThree";
import ScholarshipsFour from "../Components/ScholarshipsFour";
import ContactUs from "../Screen/contactUs";
import ErrorPage from "../Screen/404Page";
import Login from "../Components/login/Login";
import sendOtp from "../Screen/otp/sendOtp";

const Router = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/blogPage",
    element: <BlogPage />,
  },
  {
    path: "/sendOtp",
    element: <sendOtp />,
  },
  {
    path: "/article",
    element: <ArticleMain />,
  },
  {
    path: "/finder",
    element: <Finder />,
  },
  {
    path: "/finderOne",
    element: <FinderOne />,
  },
  {
    path: "/finderTwo",
    element: <FinderTwo />,
  },
  {
    path: "/finderThree",
    element: <FinderThree />,
  },
  {
    path: "/finderFour",
    element: <FinderFour />,
  },
  {
    path: "/finderFive",
    element: <FinderFive />,
  },
  {
    path: "/finderSix",
    element: <FinderSix />,
  },
  {
    path: "/finderSeven",
    element: <FinderSeven />,
  },
  {
    path: "/finderEight",
    element: <FinderEight />,
  },
  {
    path: "/ielts",
    element: <Ielts />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/accommodation",
    element: <Accommodation />,
  },
  {
    path: "/accommodationOne",
    element: <AccommodationOne />,
  },
  {
    path: "/accommodationTwo",
    element: <AccommodationTwo />,
  },
  {
    path: "/accommodationThree",
    element: <AccommodationThree />,
  },
  {
    path: "/scholarships",
    element: <Scholarships />,
  },
  {
    path: "/scholarshipsOne",
    element: <ScholarshipsOne />,
  },
  {
    path: "/scholarshipsTwo",
    element: <ScholarshipsTwo />,
  },
  {
    path: "/scholarshipsThree",
    element: <ScholarshipsThree />,
  },
  {
    path: "/scholarshipsFour",
    element: <ScholarshipsFour />,
  },
  {
    path: "/contactUs",
    element: <ContactUs />,
  },
  {
    path: "/errorPage",
    element: <ErrorPage />,
  },
  { path: "/login", element: <Login /> },
];

function MainRoutes() {
  return (
    <Routes>
      {Router.map((route) => (
        <Route path={route.path} element={route.element}></Route>
      ))}
    </Routes>
  );
}

export default MainRoutes;

import React from "react";
import "./style.css";
import MentorImage from "../../images/img (13).png";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MentorLogo from "../../images/Vector (11).png";
import MentorLogo1 from "../../images/Vector (12).png";
import MentorLogo2 from "../../images/Group (8).png";
import MentorLogo3 from "../../images/Vector (14).png";
import { Container } from "react-bootstrap";
const handleDragStart = (e) => e.preventDefault();
const items = [
  <div className="mentorcard-center" onDragStart={handleDragStart}>
    <img src={MentorLogo} alt="" />
    <p className="text-center-mentor">
      International
      <br /> Sim Card
    </p>
  </div>,
  <div className="mentorcard-center1" onDragStart={handleDragStart}>
    <img src={MentorLogo1} alt="" />
    <p className="text-center-mentor">
      International
      <br /> money Transfer
    </p>
  </div>,
  <div className="mentorcard-center2" onDragStart={handleDragStart}>
    <img src={MentorLogo2} alt="" />
    <p className="text-center-mentor">Housing</p>
  </div>,
  <div className="mentorcard-center" onDragStart={handleDragStart}>
    <img src={MentorLogo} alt="" />
    <p className="text-center-mentor">
      International
      <br /> Sim Card
    </p>
  </div>,
  <div className="mentorcard-center3" onDragStart={handleDragStart}>
    <img src={MentorLogo3} alt="" />
    <p className="text-center-mentor">Credit Card</p>
  </div>,
  <div className="mentorcard-center1" onDragStart={handleDragStart}>
    <img src={MentorLogo1} alt="" />
    <p className="text-center-mentor">
      International
      <br /> money Transfer
    </p>
  </div>,
];
// const responsive = {
//   375: { items: 1 },
//   425: { items: 2 },
//   568: { items: 3 },
//   1024: { items: 4 },
//   1440: { items: 5 },
//   2560: { items: 5 },
// };
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 375, min: 0 },
    items: 1,
  },
};
function MentorX() {
  return (
    <div className="div-whole-men ">
      <div>
        <p className="text-mentor">
          mentor<a className="text-x">X</a>
        </p>
        <p className="text-x">
          one stop solution{" "}
          <a className="text-mentor">your study abroad needs</a>
        </p>
        <Container>
          <img src={MentorImage} alt="" className="mentor-img" />
          <div className="mentor-cors-center">
            <div className="mentor-width-card">
              {/* <AliceCarousel
                items={items}
                disableDotsControls
                responsive={responsive}
                autoPlay
                animationDuration={4000}
                mouseTracking
              /> */}
              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                transitionDuration={500}
              >
                <div
                  className="mentorcard-center"
                  onDragStart={handleDragStart}
                >
                  <img src={MentorLogo} alt="" />
                  <p className="text-center-mentor">
                    International
                    <br /> Sim Card
                  </p>
                </div>
                <div
                  className="mentorcard-center1"
                  onDragStart={handleDragStart}
                >
                  <img src={MentorLogo1} alt="" />
                  <p className="text-center-mentor">
                    International
                    <br /> money Transfer
                  </p>
                </div>
                <div
                  className="mentorcard-center2"
                  onDragStart={handleDragStart}
                >
                  <img src={MentorLogo2} alt="" />
                  <p className="text-center-mentor">Housing</p>
                </div>
                <div
                  className="mentorcard-center"
                  onDragStart={handleDragStart}
                >
                  <img src={MentorLogo} alt="" />
                  <p className="text-center-mentor">
                    International
                    <br /> Sim Card
                  </p>
                </div>
                <div
                  className="mentorcard-center3"
                  onDragStart={handleDragStart}
                >
                  <img src={MentorLogo3} alt="" />
                  <p className="text-center-mentor">Credit Card</p>
                </div>
                <div
                  className="mentorcard-center1"
                  onDragStart={handleDragStart}
                >
                  <img src={MentorLogo1} alt="" />
                  <p className="text-center-mentor">
                    International
                    <br /> money Transfer
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default MentorX;

import React, { useState, useEffect } from "react";
import "./style.css";
import Global from "../../images/Huge Global.png";
import Line from "../../images/Vector 637.png";
import Air from "../../images/Vector (17).png";
import divLine from "../../images/div.png";
import Country6 from "../../images/SVG.png";
import Arrow from "../../images/div (1).png";
import Arrow1 from "../../images/span.jss107.png";

import { Link } from "react-router-dom";
import { message } from "antd";

import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    purpose: "Bachelors",
  },
  {
    id: 2,
    purpose: "Masters",
  },
];
function FinderOne() {
  const navigate = useNavigate();
  const [purpose, setpurpose] = useState(null);
  const [universityCount, setUniversityCount] = useState(0);
  const [courseCount, setCourseCount] = useState(0);

  const handleSmallDivCountryClick = (id) => {
    const purposeData = data.find((item) => item.id === id);
    sessionStorage.setItem("purpose", purposeData.purpose);
    setpurpose(purposeData);
    const universities = [
      // Example data for universities associated with each country
      { countryId: 1, name: "University A", universityCount: 170 },
      { countryId: 2, name: "University B", universityCount: 90 },
    ];

    const courses = [
      // Example data for courses associated with each country
      { countryId: 1, name: "Course X", courseCount: 280 },
      { countryId: 2, name: "Course Y", courseCount: 190 },

      // ... (more courses for other countries)
    ];
    const selectedCountryUniversities = universities.filter(
      (uni) => uni.countryId === id
    );
    setUniversityCount(selectedCountryUniversities[0]?.universityCount || 0);

    const selectedCountryCourses = courses.filter(
      (course) => course.countryId === id
    );

    setCourseCount(selectedCountryCourses[0]?.courseCount || 0);
  };

  const handleNextButtonClick = () => {
    if (purpose) {
      console.log("Selected purpose:", purpose);

      setpurpose(null); // Clear the selected country after storing it in local storage
    } else {
      // Show a message indicating that a country needs to be selected
      alert("Please select a country before proceeding to the next step.");
    }
  };

  return (
    <div className="center-global">
      <Link to="/">
        <img className="logo-main" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <img src={Air} alt="" className="img-linee123" />
      <img src={Line} alt="" className="img-linee" />
      {purpose && (
        <div className="div-uni-count">
          <div>
            <h1>{universityCount}</h1>
            <p style={{ fontWeight: "500" }}>universities</p>
          </div>
          <img src={divLine} alt="" className="hr-line" />
          <div>
            <h1>{courseCount}</h1>
            <p style={{ fontWeight: "500" }}>courses</p>
          </div>
        </div>
      )}
      <img src={Global} alt="" className="img-linee" />
      <div>
        <div className="bg-token-div">
          <div className="div-text-div">
            <img
              src={Arrow}
              alt=""
              className="im-size12"
              onClick={() => navigate(-1)}
            />
            <p className="where-text">what degree do you want to pursue?</p>
          </div>
          <div className="div-btn-both">
            {data.map((item) => (
              <div
                className={`small-div-country ${
                  purpose && purpose.id === item.id ? "border-highlight" : ""
                }`}
                onClick={() => handleSmallDivCountryClick(item.id)}
              >
                <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />{" "}
                {item.purpose}
              </div>
            ))}
          </div>
          <Link to="/finderTwo" style={{ textDecoration: "none" }}>
            <button
              className={`btn-next ${purpose ? "" : "btn-next:disabled"}`}
              disabled={!purpose}
              onClick={handleNextButtonClick}
            >
              Next <img src={Arrow1} alt="" className="im-size" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FinderOne;

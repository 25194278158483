import React, { useRef, useState } from "react";
import "./style.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function VideoSection() {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  // Ref for the last video that triggered hover

  // Ref for the last video that triggered hover

  const handlePlay = () => {
    videoRef1.current.play();
  };
  const handlePause = () => {
    videoRef1.current.pause();
  };
  const handlePlay1 = () => {
    videoRef2.current.play();
  };
  const handlePause1 = () => {
    videoRef2.current.pause();
  };
  const handlePlay2 = () => {
    videoRef3.current.play();
  };
  const handlePause2 = () => {
    videoRef3.current.pause();
  };
  const handlePlay3 = () => {
    videoRef4.current.play();
  };
  const handlePause3 = () => {
    videoRef4.current.pause();
  };
  // const clip = document.querySelectorAll(".clip");
  // for (let i = 0; i < clip.length; i++) {
  //   clip[i].addEventListener("mouseenter", function (e) {
  //     clip[i].play();
  //   });
  //   clip[i].addEventListener("mouseout", function (e) {
  //     clip[i].pause();
  //   });
  // }
  return (
    <div>
      <div className="div-texted12 ">
        <p className="test-text23">Testimonials</p>
        <h1 className="hear-text">hear from our students</h1>
      </div>
      <div className="center-curr">
        <div className="width-cur">
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            transitionDuration={500}
          >
            <div>
              <div className="video-center">
                <div className="div-btween">
                  <button onClick={handlePlay} className="btn-play">
                    play
                  </button>
                  <button onClick={handlePause} className="btn-play">
                    pause
                  </button>
                </div>
                <video
                  src="https://thestudenthelpline.co.in/assets/video/t2.webm"
                  type="video/mp4"
                  className="video-size12 "
                  ref={videoRef1}
                />
              </div>
              <div className="div-bggg12">
                <p>Kansha</p>
                <p>
                  The Student Helpline comes highly recommended. Their
                  comprehensive support and guidance can make your global
                  education dreams a reality
                </p>
              </div>
            </div>
            <div>
              <div className="video-center">
                {" "}
                <div className="div-btween">
                  <button onClick={handlePlay1} className="btn-play">
                    play
                  </button>
                  <button onClick={handlePause1} className="btn-play">
                    pause{" "}
                  </button>
                </div>
                <video
                  src="https://thestudenthelpline.co.in/assets/video/t3.webm"
                  type="video/mp4"
                  className="video-size12 "
                  ref={videoRef2}
                />
              </div>
              <div className="div-bggg12">
                <p>Jatin</p>
                <p>
                  With their extensive expertise and unwavering support, your
                  journey to international education will be smooth and
                  rewarding
                </p>
              </div>
            </div>
            <div>
              <div className="video-center">
                <div className="div-btween">
                  <button onClick={handlePlay2} className="btn-play">
                    play
                  </button>
                  <button onClick={handlePause2} className="btn-play">
                    pause{" "}
                  </button>
                </div>
                <video
                  src="https://thestudenthelpline.co.in/assets/video/t6.webm"
                  type="video/mp4"
                  className="video-size12 "
                  ref={videoRef3}
                />
              </div>

              <div className="div-bggg12">
                <p>Ruchita</p>
                <p>
                  The Student Helpline comes highly recommended. Their
                  comprehensive support and guidance can make your global
                  education dreams a reality
                </p>
              </div>
            </div>
            <div>
              <div className="video-center">
                <div className="div-btween">
                  <button onClick={handlePlay3} className="btn-play">
                    play
                  </button>
                  <button onClick={handlePause3} className="btn-play">
                    pause
                  </button>
                </div>
                <video
                  src="https://thestudenthelpline.co.in/assets/video/t2.webm"
                  type="video/mp4"
                  className="video-size12 "
                  ref={videoRef4}
                />
              </div>
              <div className="div-bggg12">
                <p>Nidhi</p>
                <p>
                  I strongly endorse The Student Helpline for anyone considering
                  studying abroad
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default VideoSection;

import React from "react";
import "./style.css";
import { Avatar, Button, Card, Col, Row, Space } from "antd";

function Acceptability() {
  return (
    <div className="fullwi">
      <p className="abietxt">Academic Test Format</p>
      <Row className="testfor">
        <Col className="cdfr">
          <Avatar
            className="avatarsi"
            src="../images/flag6.svg"
          />
          <p className="testlo">United States</p>
        </Col>
        <Col className="cdfr">
          <Avatar
            className="avatarsi"
            src="../images/flag5.svg"
          />
          <p className="testlo">United Kingdom</p>
        </Col>{" "}
        <Col className="cdfr">
          <Avatar
            className="avatarsi"
            src="../images/flag4.svg"
          />
          <p className="testlo">New Zealand</p>
        </Col>
      </Row>
      <Row className="testfor">
        <Col className="cdfr">
          <Avatar
            className="avatarsi"
            src="../images/flag3.svg"
          />
          <p className="testlo">Australia</p>
        </Col>
        <Col className="cdfr">
          <Avatar
            className="avatarsi"
            src="../images/flag2.svg"
          />
          <p className="testlo">Canada</p>
        </Col>{" "}
        <Col className="cdfr">
          <Avatar
            className="avatarsi"
            src="../images/flag1.svg"
          />
          <p className="testlo">Ireland</p>
        </Col>
      </Row>

      <div>
        <p className="malor">IELTS Programs Of The Student Helpline</p>
        <Row className="rowlastcard">
          <Col>
            <Card bordered={false} className="cardlorempkg">
              <p className="ielpre">IELTS PREMIUM+</p>
              <hr className="hr" />
              <p className="ielpre">$299</p>
              <hr className="hr" />
              <p className="newlor">
                20+ Hours of live classes 4 Week Course 3-5 Full Length IELTS
                simulation Tests 50+ Reading and Listening Tests 25 Speaking and
                30 Writing Evaluations Study Materials Grammar Improvement
                Classes Doubt Solving Sessions – Everyday Dedicated Students
                Success Manager
              </p>
            </Card>
          </Col>
          <Col>
            {" "}
            <Card bordered={false} className="cardlorempkg">
              <p className="ielpre">IELTS 1 ON 1</p>
              <hr className="hr"></hr>
              <p className="ielpre">$299</p>
              <hr className="hr"></hr>

              <p className="newlor">
                20+ Hours of live classes 4 Week Course 3 Full Length IELTS
                simulation Tests 30+ Reading and Listening Tests 15 Speaking and
                20 Writing Evaluations Study Materials Grammar Improvement
                Classes Doubt Solving Sessions – Twice In a Week Dedicated
                Students Success Manager
              </p>
            </Card>
          </Col>
          <img className="abs1" alt="abc" src="../images/abs1.svg" />
        </Row>
        <div className="inbt">
          <img className="abs" alt="abc" src="../images/abs.svg" />{" "}
          <div className="baadcen">
            <button className="lorembutt23">
              See Demo <img alt="abc" src="../images/right.svg" />
            </button>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default Acceptability;

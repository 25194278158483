import React from "react";
import Footer from "../../Components/footer";
import CollapsibleExample from "../../Components/navbar";

import "./style.css";
function sendOtp() {
  return (
    <>
      <CollapsibleExample />
      <div>sendOtp</div>
      <Footer />
    </>
  );
}

export default sendOtp;

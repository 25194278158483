import React from "react";
import "./style.css";
import Footer from "../../Components/footer";
import CollapsibleExample from "../../Components/navbar";
import HeroAboutUs from "../../Components/heroabout";
import OurHistory from "../../Components/history";
import { Container } from "react-bootstrap";
import MeetOur from "../../Components/meetourpeople";
import ThreeCard from "../../Components/threeaboutcard";
import MeetPartner from "../../Components/meetpartner";
import WhyUsAbout from "../../Components/whyusabout";
import WhyAboutUs from "../../Components/whyaboutus";

function AboutUs() {
  return (
    <div>
      {" "}
      <div className="bg-clr">
        {" "}
        <CollapsibleExample />
        <HeroAboutUs />
        <WhyAboutUs />
        <OurHistory />
        <MeetOur />
        <br />
        <ThreeCard />
        <MeetPartner />
        <WhyUsAbout />
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;

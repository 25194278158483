import React from "react";
import "./style.css";
import { Card, Col, Row } from "antd";

function Academic() {
  return (
    <div>
      <p className="abietxt">Academic Test Format</p>
      <div>
        <div className="imgesielts">
          {" "}
          <img
            className="img"
            alt="abc"
            src="../images/ieltsbig3.png"
          ></img>
        </div>
        <Row className="abcewqd">
          <Col className="cardacademics">
            <Card className="cardwidths">
              <div className="cardonetwo">
                <div>
                  <img alt="abc" src="../images/head.svg" />
                </div>
                <div>
                  <p className="allps">Listening</p>
                  <p className="yhsame">
                    Enhance your ability to comprehend spoken English in various
                    contexts, such as lectures, conversations, and
                    presentations.
                  </p>
                </div>
              </div>
            </Card>
            <Card className="cardwidths">
              <div className="cardonetwo">
                <div>
                  <img alt="abc" src="../images/pencil.svg" />
                </div>
                <div>
                  <p className="allps1">Writing</p>
                  <p className="yhsame">
                    Improve your reading skills and learn techniques to
                    comprehend complex passages effectively.
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="abcewqd">
          <Col className="cardacademics1">
            {" "}
            <Card className="cardwidths">
              <div className="cardonetwo">
                <div>
                  <img alt="abc" src="../images/micro.svg" />
                </div>
                <div>
                  <p className="allps2">Speaking</p>
                  <p className="yhsame">
                    Build confidence in expressing yourself fluently and
                    coherently in both informal and formal situations.
                  </p>
                </div>
              </div>
            </Card>
            <Card className="cardwidths">
              <div className="cardonetwo">
                <div>
                  <img alt="abc" src="../images/books.svg" />
                </div>
                <div>
                  <p className="allps">Writing</p>
                  <p className="yhsame">
                    Develop your writing skills for different tasks, such as
                    essays, reports, and letters.
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Academic;

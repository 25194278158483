import React from "react";
import "./login.css";

import { Button, Checkbox, Form, Input, Row } from "antd";

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

function Login() {
  return (
    <div>
      <img
        className="imglogings delimages"
        alt="abc"
        src="../images/Gradient.png"
      />{" "}
      <div className="signup">
        <Button className="btnsign" style={{ marginRight: "2rem" }}>
          Sign Up
        </Button>
      </div>
      <div className="centertxtlog">
        {" "}
        <p className="logtxt">Login to your account</p>
        <p>Welcome Back! Please enter your details</p>
      </div>
      <div className="div-c">
        <div className="seconddivlogg">
          {" "}
          <div className="centerdispi">
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                Email
                <Input className="inplogf" placeholder="Enter Your Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                Password
                <Input.Password
                  className="inplogf"
                  placeholder="Enter Your Password"
                />
              </Form.Item>
              <div className="rowoge">
                <div className="rowloge">
                  <Checkbox onChange={onChange}>Remember for 30</Checkbox>
                  <a href="">Forgot Password</a>
                </div>
              </div>
              <Form.Item>
                <div className="centerdisp">
                  <button className="btnloglastt" htmlType="submit">
                    Submit
                  </button>
                </div>
              </Form.Item>
              <div className="centerdisp">
                <button className="btnloglasttt">
                  {" "}
                  <img alt="abc" src="../images/Socialicon.png" />
                  &nbsp;&nbsp; Sign In with Google
                </button>
              </div>
              <p className="acc">
                Don't have an account? <a href="">Sign up</a>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

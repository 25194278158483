import React from "react";
import { Button, Col, Row, Form, Input, Select, Checkbox } from "antd";
import Ring1 from "../../images/Group 25.png";
import "./style.css";
import SMS from "../../images/sms.png";
import Phone from "../../images/call-calling.png";
import Location from "../../images/location.png";
const { Option } = Select;
const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
function ContactUs() {
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <img src={Ring1} alt="" className="contct-logo-side-div" />
      <button className="btn-sign-up">Sign Up</button>
      <div className="padding-whole-div">
        <div>
          <h2 className="center-contact-text">Contact us</h2>
          <p className="text-our">
            Our friendly team would love to hear from you.
          </p>
        </div>
        <div className="contact-col">
          <div className="box-contact">
            <Row justify="center">
              <Col xl={12} lg={12} md={12} xs={24}>
                <div className="contact-col">
                  <div>
                    <Form
                      name="basic"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <div className="div-form-input">
                        <Form.Item
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Please input firstname!",
                            },
                          ]}
                        >
                          <p className="input-name">First name</p>
                          <Input
                            className="input-small"
                            placeholder="First name "
                          />
                        </Form.Item>

                        <Form.Item
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Please input lastname!",
                            },
                          ]}
                        >
                          <p className="input-name">Last name</p>
                          <Input
                            className="input-small"
                            placeholder="Last name"
                          />
                        </Form.Item>
                      </div>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input email!",
                          },
                        ]}
                      >
                        <p className="input-name">Email</p>
                        <Input
                          className="input-big"
                          placeholder="you@company.com"
                        />
                      </Form.Item>
                      <Form.Item
                        name="phonenumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input your number!",
                          },
                        ]}
                      >
                        <p className="input-name">Phone Number</p>
                        <Input
                          addonBefore={prefixSelector}
                          className="input-big"
                          placeholder="+1 (923) 000-0000"
                        />
                      </Form.Item>

                      <Form.Item
                        name="message"
                        rules={[
                          {
                            required: true,
                            message: "Please input message!",
                          },
                        ]}
                      >
                        <p className="input-name">Message</p>
                        <Input.TextArea
                          showCount
                          maxLength={1000}
                          rows={4}
                          placeholder="leave a message"
                          className="input-big"
                        />
                      </Form.Item>
                      <Form.Item>
                        <br />
                        <Form.Item name="agreement">
                          <Checkbox style={{ color: "#4E566D" }}>
                            You agree to our friendly privacy policy.
                          </Checkbox>
                        </Form.Item>
                        <button
                          type="primary"
                          htmlType="submit"
                          className="btn-form"
                        >
                          Send message
                        </button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24}>
                <div className="contact-col">
                  <div>
                    <div>
                      <img src={SMS} alt="" style={{ marginBottom: ".5rem" }} />
                      <p className="text-mail">Email</p>
                      <p className="text-mail1">
                        Our friendly team is here to help.
                      </p>
                      <p className="txt-phone">hi@gmail.com</p>
                    </div>
                    <div>
                      <img
                        src={Phone}
                        alt=""
                        style={{ marginBottom: ".5rem" }}
                      />
                      <p className="text-mail">Phone</p>
                      <p className="text-mail1">Mon-Fri from 8am to 5pm.</p>
                      <p className="txt-phone">+1 234 00000</p>
                    </div>
                    <div>
                      <img
                        src={Location}
                        alt=""
                        style={{ marginBottom: ".5rem" }}
                      />
                      <p className="text-mail">Office</p>
                      <p className="text-mail1">
                        Come say hello at our office .
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

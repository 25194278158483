import React, { useState, useEffect } from "react";
import "./style.css";
import Hand from "../../images/Untitled design (20) 1.png";
import Ring from "../../images/Group 1000004381.png";
import Ring1 from "../../images/Group 25.png";
import Plane from "../../images/Group 1000000968.png";
import Country6 from "../../images/SVG (14).png";
import Country7 from "../../images/SVG (15).png";
import Arrow from "../../images/span.jss107.png";
import Arrow1 from "../../images/div (1).png";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Row, message } from "antd";
import { Link } from "react-router-dom";
import { course } from "../../helper/axios";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    intake: "Jul - Sep 2023",
  },
  {
    id: 2,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    intake: "Oct - Dec 2023",
  },
  {
    id: 3,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    intake: "Jan - Mar 2024",
  },
  {
    id: 4,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    intake: "Apr - Jun 2024",
  },
  {
    id: 5,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    intake: "Jul - Sep 2024",
  },
  {
    id: 6,
    img: <img src={Country7} alt="" style={{ marginRight: ".5rem" }} />,
    intake: "Not Decided",
  },
];
function ScholarshipsThree() {
  const navigate = useNavigate();

  const [selectedintakes, setSelectedintakes] = useState(null);
  const [loading, setLoading] = useState();
  const handleSmallDivCountryClick = (id) => {
    const selectedintakesData = data.find((item) => item.id === id);
    sessionStorage.setItem("selectedintakes", selectedintakesData.intake);
    setSelectedintakes(selectedintakesData);
  };

  const handleNextButtonClick = () => {
    if (selectedintakes) {
      console.log("selectedintakes:", selectedintakes);
      createCourse();
      setSelectedintakes(null); // Clear the selected country after storing it in local storage
    } else {
      // Show a message indicating that a country needs to be selected
      alert("Please select a country before proceeding to the next step.");
    }
  };
  const storedItem = sessionStorage.getItem("selectedCountries");
  const storedItem1 = sessionStorage.getItem("selectedfields");
  const storedItem2 = sessionStorage.getItem("selectedintakes");
  const storedItem3 = sessionStorage.getItem("purposes");

  console.log("select", storedItem, storedItem1, storedItem2, storedItem3);

  const createCourse = (values) => {
    setLoading(true);
    const data = {
      country: storedItem,
      field: storedItem1,
      intake: storedItem2,
      purpose: storedItem3,
    };

    course({
      method: "post",
      data: data,
    })
      .then((res) => {
        setLoading(false);
        console.log(res.data.user, "api");
        sessionStorage.removeItem("selectedCountries");
        sessionStorage.removeItem("selectedfields");
        sessionStorage.removeItem("selectedintakes");
        sessionStorage.removeItem("purposes");
        message.success("API call successful!");
        // Do something after successful API call (e.g., navigate to another page)
      })
      .catch((error) => {
        setLoading(false);
        // Handle error if the API call fails
        message.error("API call failed."); // Show error messages
      });
  };
  return (
    <div className="whole-div-findd">
      <Link to="/">
        <img className="logoacc" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <div>
        <Row justify="center">
          <Col xxl={12} xl={12} lg={12} md={10}>
            <div className="center-find">
              <div>
                <img src={Ring} alt="" className="ring-img" />
                <h1 className="finetext-new">
                  “One step closer to <br /> Finding the best
                  <br /> <a className="finetext-new1">Scholarships</a> for
                  <br /> you”
                </h1>
              </div>
            </div>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={10}>
            <div className="center-find12">
              <div>
                <img src={Ring1} alt="" className="ring1-div" />
                <img src={Hand} alt="" className="div-img-hand22" />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <di>
          <img src={Plane} alt="" className="plane-img" />
        </di>
        <br />
        <br />
        <div className="center-token-div-whole">
          <div className="bg-token-div">
            <div className="div-text-div">
              <img
                src={Arrow1}
                alt=""
                className="im-size12"
                onClick={() => navigate(-1)}
              />
              <p className="where-text">What is your preferred intake?</p>
            </div>
            <Row justify="center" className="calandeer-width ">
              {data.map((item) => (
                <Col xl={8}>
                  <div
                    className={`small-div-country1255  ${
                      selectedintakes && selectedintakes.id === item.id
                        ? "border-highlight1"
                        : ""
                    }`}
                    onClick={() => handleSmallDivCountryClick(item.id)}
                  >
                    <div>{item.img}</div>
                    <div>{item.intake}</div>
                  </div>
                </Col>
              ))}
            </Row>
            <Link to="/scholarshipsFour" style={{ textDecoration: "none" }}>
              <button
                className={`btn-next ${
                  selectedintakes ? "" : "btn-next:disabled"
                }`}
                disabled={!selectedintakes}
                onClick={handleNextButtonClick}
              >
                Next <img src={Arrow} alt="" className="im-size" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScholarshipsThree;

import React, { useState, useEffect } from "react";
import "./style.css";
import Global from "../../images/Huge Global.png";
import Line from "../../images/Vector 637.png";
import Air from "../../images/Vector11.png";
import divLine from "../../images/div.png";
import Country6 from "../../images/SVG (21).png";
import Country9 from "../../images/SVG (15).png";
import Arrow from "../../images/div (1).png";
import { course } from "../../helper/axios";
import { Col, InputNumber, Row, Slider, Space, message } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    yes: "Yes",
  },
  {
    id: 2,
    img: <img src={Country9} alt="" style={{ marginRight: ".5rem" }} />,
    yes: "None",
  },
];
function FinderSeven() {
  const navigate = useNavigate();

  const [inputValueexper, setInputValueexper] = useState(1);
  const [loading, setLoading] = useState();
  const onChange = (newValue) => {
    setInputValueexper(newValue);
  };
  const [selectedyes, setSelectedyes] = useState(null);
  const handleSmallDivCountryClick = (id) => {
    const selectedyesData = data.find((item) => item.id === id);
    sessionStorage.setItem("selectedyes", selectedyesData.yes);

    setSelectedyes(selectedyesData);
  };

  const handleNextButtonClick = () => {
    if (selectedyes) {
      console.log("selectedyes:", selectedyes);
      createCourse(); // Call the createCourse function
    } else {
      alert("Please select an option before proceeding to the next step.");
    }
  };
  const storedItem = sessionStorage.getItem("selectedCountry");
  const storedItem1 = sessionStorage.getItem("selectedtest1");
  const storedItem2 = sessionStorage.getItem("purpose");
  const storedItem3 = sessionStorage.getItem("selectedintake");
  const storedItem4 = sessionStorage.getItem("selectededucation");
  const storedItem5 = sessionStorage.getItem("selectedtest2");
  const storedItem6 = sessionStorage.getItem("selectedfield");
  const storedItem7 = sessionStorage.getItem("selectedyes");
  const storedItem8 = sessionStorage.getItem("inputValue");
  const storedItem9 = sessionStorage.getItem("inputValues");
  const storedItem10 = sessionStorage.getItem("inputValueap");
  const storedItem11 = sessionStorage.getItem("inputValueexper");
  console.log(
    "select",
    storedItem,
    storedItem1,
    storedItem2,
    storedItem3,
    storedItem4,
    storedItem5,
    storedItem6,
    storedItem7,
    storedItem8,
    storedItem9,
    storedItem10,
    storedItem11
  );

  const createCourse = (values) => {
    setLoading(true);
    const data = {
      country: storedItem,
      test1: storedItem1,
      test1Number: storedItem9,
      purpose: storedItem2,
      intake: storedItem3,
      education: storedItem4,
      marks: storedItem8,
      test2: storedItem5,
      test2Number: storedItem10,
      field: storedItem6,
      workExperience: storedItem11,
    };

    course({
      method: "post",
      data: data,
    })
      .then((res) => {
        setLoading(false);
        console.log(res.data, "api");
        sessionStorage.removeItem("selectedCountry");
        sessionStorage.removeItem("selectedtest1");
        sessionStorage.removeItem("purpose");
        sessionStorage.removeItem("selectedintake");
        sessionStorage.removeItem("selectededucation");
        sessionStorage.removeItem("selectedtest2");
        sessionStorage.removeItem("selectedfield");
        sessionStorage.removeItem("selectedyes");
        sessionStorage.removeItem("inputValue");
        sessionStorage.removeItem("inputValues");
        sessionStorage.removeItem("inputValueap");
        sessionStorage.removeItem("inputValueexper");
        message.success("API call successful!");
        // Do something after successful API call (e.g., navigate to another page)
      })
      .catch((error) => {
        setLoading(false);
        // Handle error if the API call fails
        message.error("API call failed."); // Show error messages
      });
  };
  useEffect(() => {
    // Save the inputValueexper in session storage whenever it changes
    sessionStorage.setItem("inputValueexper", inputValueexper.toString());
  }, [inputValueexper]);

  // Retrieve the stored inputValueexper from session storage on component mount
  useEffect(() => {
    const storedInputValue = sessionStorage.getItem("inputValueexper");
    // Convert the stored value back to a number using Number() function
    if (storedInputValue !== null) {
      setInputValueexper(Number(storedInputValue));
    }
  }, []);
  return (
    <div className="center-global">
      <Link to="/">
        <img className="logo-main" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <img src={Air} alt="" className="img-linee0000" />
      <img src={Line} alt="" className="img-linee" />
      <div className="div-uni-count">
        <div>
          <h1>150</h1>
          <p style={{ fontWeight: "500" }}>universities</p>
        </div>
        <img src={divLine} alt="" className="hr-line" />
        <div>
          <h1>1725</h1>
          <p style={{ fontWeight: "500" }}>courses</p>
        </div>
      </div>
      <img src={Global} alt="" className="img-linee" />
      <div>
        <div className="bg-token-div">
          <div className="div-text-div">
            <img
              src={Arrow}
              alt=""
              className="im-size12"
              onClick={() => navigate(-1)}
            />
            <p className="where-text">do you have any work experience?</p>
          </div>

          <div className="div-btn-both0000">
            {data.map((item) => (
              <div
                className={`small-div-country1289  ${
                  selectedyes && selectedyes.id === item.id
                    ? "border-highlight1"
                    : ""
                }`}
                onClick={() => handleSmallDivCountryClick(item.id)}
              >
                <div>{item.img}</div>
                {item.yes}
              </div>
            ))}
          </div>
          <div className="div-slide">
            <div>
              <p>how many years of experience</p>
              <div className="silde-bg">
                <Slider
                  min={1}
                  max={10}
                  onChange={onChange}
                  value={
                    typeof inputValueexper === "number" ? inputValueexper : 0
                  }
                  className="slides-width89"
                />
              </div>
            </div>
            <InputNumber
              min={1}
              max={10}
              style={{
                margin: "0 16px",
                marginTop: "2rem",
              }}
              value={inputValueexper}
              onChange={onChange}
            />
          </div>
          <Link to="/finderEight" style={{ textDecoration: "none" }}>
            <button
              className={`btn-next ${selectedyes ? "" : "btn-next:disabled"}`}
              disabled={!selectedyes}
              onClick={handleNextButtonClick}
            >
              {loading ? "Loading..." : "Next"}{" "}
              <img src={Arrow} alt="" className="im-size" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FinderSeven;

import React from "react";
import "./cards.css";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container } from "react-bootstrap";
// const handleDragStart = (e) => e.preventDefault();
// const items = [
//   <div>
//     <div className="mentorcard-center1-about" onDragStart={handleDragStart}>
//       <img
//         src="https://s3-alpha-sig.figma.com/img/7463/f84b/fa6fc970ac09b6737702d3dfd6f28f92?Expires=1691366400&Signature=ZcEReGtmdmzZ1Yro4LUpC0ObQuwDuFtOB-K5~bQK-ozc8fEBe60Hi3jb2W6C6PRD2dHO1EwPxaDgeHGuci-SANzVIV-WaOjCujRiOQl7XHVIS5UIl7WLpEj6YcFinmBnEwD9mklVqX0pH2iniTQ16K8FgMqTkO9SlgYCqV4PeSTxgaKM2wTXtHguaN7wG6NkX9d1YMDclS9mrHvWZUjtpZ~PEf41zd0whKuGiZBlOOGppCs49YS9IbYiKZ3nxmcc5b2vzBa6LXhAwf6R5wh8HhNXObR0NEkv5FcgnHxeSsB8gWguX20YOfOjVBWMIsoCWjFtCQscCCtWa38qdXs6dg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="text-center-mentor-about">Mentorship from Abroad</p>
//       <p className="cardtxtabot">
//         Explore a wide range of courses and
//         <br /> find your ideal academic path with our
//         <br /> comprehensive search tool.
//       </p>{" "}
//     </div>{" "}
//   </div>,
//   <div>
//     <div className="mentorcard-center2-about" onDragStart={handleDragStart}>
//       <img
//         className="widthimgabt"
//         src="https://s3-alpha-sig.figma.com/img/bb95/7bb1/4e5cde66946d8007842c477fdc3f3daa?Expires=1691366400&Signature=IhMhyUXBDIYRximT0XQB3bUjdisXRrOThuei2edbdA4pOPtCM5McGqDSPEgVkzap8BgdWbADw4BoxZ52HcF3BDc1IesuD6rOkJ8781-4ojk7YGqJ5QMmUD2qv2QD7sSYV95a3c1nd7S~oHmgFFTIXFQXQsS2GL7R-4sygr3ngREa458ev5E~I6IgayaFJGpv4YgeLlkIu~TZzeVNy4gqRy-AgG0ERSO8KOPy9lde~jZlNgeqxEwB4LKyu2Ww65amFCes5nTB6i06JjwZIiWOQUWTf2NRVl8fhx2NTek5vkccMDS4iPguK2U98pgHSM0fbaGTDAdL4WLJ1Iyiub4ukQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="recomtxt">Get Recommendation</p>
//       <p className="cardtxtabot">
//         Unlock outstanding recommendations from
//         international educators to enrich
//         <br /> your academic journey
//       </p>{" "}
//     </div>{" "}
//   </div>,
//   <div>
//     <div className="mentorcard-center3-about" onDragStart={handleDragStart}>
//       <img
//         className="widthimgabt"
//         src="https://s3-alpha-sig.figma.com/img/4af8/cf91/16f947b9e75a88a488ecdea3a2a4fffd?Expires=1691366400&Signature=Wm5WkFTQdxVriCe72YXFaEs7DgVxroD-nyf0d09qQk4wcsf3ZSIdG~GvjEoIoXLHVOZ6bNFOIx1Xa7nGEE9UXky6MMcffl5NoHKH3onQvenMfpPp8IidSoIOY9aVEoFfkyY6Iz9TbuHYge133kDZhzmZ9tIDgL6ti9Ma~yFXDOzWUvN~N8OLIWVboQArneItk5Rfr-aXN6S4OwGY6Y8mraiHECJ0EdTf27ecwS9kqqLilFjynBHQdpAPOAHzHnY1-wwsZgpPKPNwg7GVGZ83GmWebKUTkCUkSTw0HjPSCJZK8Qo3h9a6qzfZbf0QlOAKhgNLWZI~ThxVtxoxypXDQg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="searcour">Search Course</p>
//       <p className="cardtxtabot">
//         Unlock outstanding recommendations from
//         international educators to enrich
//         <br /> your academic journey
//       </p>{" "}
//     </div>{" "}
//   </div>,
//   <div className="mentorcard-center1-about" onDragStart={handleDragStart}>
//     <img
//       src="https://s3-alpha-sig.figma.com/img/7463/f84b/fa6fc970ac09b6737702d3dfd6f28f92?Expires=1691366400&Signature=ZcEReGtmdmzZ1Yro4LUpC0ObQuwDuFtOB-K5~bQK-ozc8fEBe60Hi3jb2W6C6PRD2dHO1EwPxaDgeHGuci-SANzVIV-WaOjCujRiOQl7XHVIS5UIl7WLpEj6YcFinmBnEwD9mklVqX0pH2iniTQ16K8FgMqTkO9SlgYCqV4PeSTxgaKM2wTXtHguaN7wG6NkX9d1YMDclS9mrHvWZUjtpZ~PEf41zd0whKuGiZBlOOGppCs49YS9IbYiKZ3nxmcc5b2vzBa6LXhAwf6R5wh8HhNXObR0NEkv5FcgnHxeSsB8gWguX20YOfOjVBWMIsoCWjFtCQscCCtWa38qdXs6dg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//       alt=""
//     />
//     <p className="text-center-mentor-about">Mentorship from Abroad</p>
//     <p className="cardtxtabot">
//       Receive invaluable mentorship from
//       <br /> experts abroad to guide your <br /> academic and personal growth
//     </p>
//   </div>,

//   <div>
//     <div className="mentorcard-center2-about" onDragStart={handleDragStart}>
//       <img
//         className="widthimgabt"
//         src="https://s3-alpha-sig.figma.com/img/bb95/7bb1/4e5cde66946d8007842c477fdc3f3daa?Expires=1691366400&Signature=IhMhyUXBDIYRximT0XQB3bUjdisXRrOThuei2edbdA4pOPtCM5McGqDSPEgVkzap8BgdWbADw4BoxZ52HcF3BDc1IesuD6rOkJ8781-4ojk7YGqJ5QMmUD2qv2QD7sSYV95a3c1nd7S~oHmgFFTIXFQXQsS2GL7R-4sygr3ngREa458ev5E~I6IgayaFJGpv4YgeLlkIu~TZzeVNy4gqRy-AgG0ERSO8KOPy9lde~jZlNgeqxEwB4LKyu2Ww65amFCes5nTB6i06JjwZIiWOQUWTf2NRVl8fhx2NTek5vkccMDS4iPguK2U98pgHSM0fbaGTDAdL4WLJ1Iyiub4ukQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="recomtxt">Get Recommendation</p>
//       <p className="cardtxtabot">
//         Unlock outstanding recommendations from
//         international educators to enrich
//         <br /> your academic journey
//       </p>{" "}
//     </div>{" "}
//   </div>,
//   <div>
//     <div className="mentorcard-center3-about" onDragStart={handleDragStart}>
//       <img
//         className="widthimgabt"
//         src="https://s3-alpha-sig.figma.com/img/4af8/cf91/16f947b9e75a88a488ecdea3a2a4fffd?Expires=1691366400&Signature=Wm5WkFTQdxVriCe72YXFaEs7DgVxroD-nyf0d09qQk4wcsf3ZSIdG~GvjEoIoXLHVOZ6bNFOIx1Xa7nGEE9UXky6MMcffl5NoHKH3onQvenMfpPp8IidSoIOY9aVEoFfkyY6Iz9TbuHYge133kDZhzmZ9tIDgL6ti9Ma~yFXDOzWUvN~N8OLIWVboQArneItk5Rfr-aXN6S4OwGY6Y8mraiHECJ0EdTf27ecwS9kqqLilFjynBHQdpAPOAHzHnY1-wwsZgpPKPNwg7GVGZ83GmWebKUTkCUkSTw0HjPSCJZK8Qo3h9a6qzfZbf0QlOAKhgNLWZI~ThxVtxoxypXDQg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="searcour">Search Course</p>
//       <p className="cardtxtabot">
//         Explore a wide range of courses and
//         <br /> find your ideal academic path with our
//         <br /> comprehensive search tool.
//       </p>{" "}
//     </div>{" "}
//   </div>,
//   <div className="mentorcard-center1-about" onDragStart={handleDragStart}>
//     <img
//       src="https://s3-alpha-sig.figma.com/img/7463/f84b/fa6fc970ac09b6737702d3dfd6f28f92?Expires=1691366400&Signature=ZcEReGtmdmzZ1Yro4LUpC0ObQuwDuFtOB-K5~bQK-ozc8fEBe60Hi3jb2W6C6PRD2dHO1EwPxaDgeHGuci-SANzVIV-WaOjCujRiOQl7XHVIS5UIl7WLpEj6YcFinmBnEwD9mklVqX0pH2iniTQ16K8FgMqTkO9SlgYCqV4PeSTxgaKM2wTXtHguaN7wG6NkX9d1YMDclS9mrHvWZUjtpZ~PEf41zd0whKuGiZBlOOGppCs49YS9IbYiKZ3nxmcc5b2vzBa6LXhAwf6R5wh8HhNXObR0NEkv5FcgnHxeSsB8gWguX20YOfOjVBWMIsoCWjFtCQscCCtWa38qdXs6dg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//       alt=""
//     />
//     <p className="text-center-mentor-about">Mentorship from Abroad</p>
//     <p className="cardtxtabot">
//       Receive invaluable mentorship from
//       <br /> experts abroad to guide your <br /> academic and personal growth
//     </p>
//   </div>,
//   <div>
//     <div className="mentorcard-center2-about" onDragStart={handleDragStart}>
//       <img
//         className="widthimgabt"
//         src="https://s3-alpha-sig.figma.com/img/bb95/7bb1/4e5cde66946d8007842c477fdc3f3daa?Expires=1691366400&Signature=IhMhyUXBDIYRximT0XQB3bUjdisXRrOThuei2edbdA4pOPtCM5McGqDSPEgVkzap8BgdWbADw4BoxZ52HcF3BDc1IesuD6rOkJ8781-4ojk7YGqJ5QMmUD2qv2QD7sSYV95a3c1nd7S~oHmgFFTIXFQXQsS2GL7R-4sygr3ngREa458ev5E~I6IgayaFJGpv4YgeLlkIu~TZzeVNy4gqRy-AgG0ERSO8KOPy9lde~jZlNgeqxEwB4LKyu2Ww65amFCes5nTB6i06JjwZIiWOQUWTf2NRVl8fhx2NTek5vkccMDS4iPguK2U98pgHSM0fbaGTDAdL4WLJ1Iyiub4ukQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="recomtxt">Get Recommendation</p>
//       <p className="cardtxtabot">
//         Unlock outstanding recommendations from <br />
//         international educators to enrich
//         <br /> your academic journey
//       </p>{" "}
//     </div>{" "}
//   </div>,
//   <div>
//     <div className="mentorcard-center3-about" onDragStart={handleDragStart}>
//       <img
//         className="widthimgabt"
//         src="https://s3-alpha-sig.figma.com/img/4af8/cf91/16f947b9e75a88a488ecdea3a2a4fffd?Expires=1691366400&Signature=Wm5WkFTQdxVriCe72YXFaEs7DgVxroD-nyf0d09qQk4wcsf3ZSIdG~GvjEoIoXLHVOZ6bNFOIx1Xa7nGEE9UXky6MMcffl5NoHKH3onQvenMfpPp8IidSoIOY9aVEoFfkyY6Iz9TbuHYge133kDZhzmZ9tIDgL6ti9Ma~yFXDOzWUvN~N8OLIWVboQArneItk5Rfr-aXN6S4OwGY6Y8mraiHECJ0EdTf27ecwS9kqqLilFjynBHQdpAPOAHzHnY1-wwsZgpPKPNwg7GVGZ83GmWebKUTkCUkSTw0HjPSCJZK8Qo3h9a6qzfZbf0QlOAKhgNLWZI~ThxVtxoxypXDQg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
//         alt=""
//       />
//       <p className="searcour">Search Course</p>
//       <p className="cardtxtabot">
//         Explore a wide range of courses and
//         <br /> find your ideal academic path with our
//         <br /> comprehensive search tool.
//       </p>{" "}
//     </div>{" "}
//   </div>,
// ];
// const responsive = {
//   375: { items: 1 },
//   425: { items: 1 },
//   568: { items: 2 },
//   1024: { items: 2 },
//   1440: { items: 3 },
//   2560: { items: 3 },
// };
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Cardabwhy() {
  return (
    <div className="div-whole-men ">
      <div>
        <Container>
          <div className="mentor-cors-center">
            <div className="mentor-width-card22">
              {/* <AliceCarousel
                items={items}
                disableDotsControls
                responsive={responsive}
                infinite
                autoPlay
                animationDuration={5000}
              /> */}
              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                transitionDuration={500}
              >
                <div>
                  <div className="mentorcard-center1-about">
                    <img
                      className="widthimgabt"
                      src="../images/abouttwo.png"
                      alt=""
                    />
                    <p className="text-center-mentor-about">
                      Mentorship from Abroad
                    </p>
                    <p className="cardtxtabot">
                      Explore a wide range of courses and
                      <br /> find your ideal academic path with our
                      <br /> comprehensive search tool.
                    </p>{" "}
                  </div>{" "}
                </div>
                <div>
                  <div className="mentorcard-center2-about">
                    <img
                      className="widthimgabt"
                      src="../images/aboutthree.png"
                      alt=""
                    />
                    <p className="recomtxt">Get Recommendation</p>
                    <p className="cardtxtabot">
                      Unlock outstanding recommendations from international
                      educators to enrich
                      <br /> your academic journey
                    </p>{" "}
                  </div>{" "}
                </div>
                <div>
                  <div className="mentorcard-center3-about">
                    <img
                      className="widthimgabt"
                      src="../images/aboutfour.png"
                      alt=""
                    />
                    <p className="searcour">Search Course</p>
                    <p className="cardtxtabot">
                      Unlock outstanding recommendations from international
                      educators to enrich
                      <br /> your academic journey
                    </p>{" "}
                  </div>{" "}
                </div>
                <div className="mentorcard-center1-about">
                  <img
                      src="../images/abouttwo.png"
                      alt=""
                  />
                  <p className="text-center-mentor-about">
                    Mentorship from Abroad
                  </p>
                  <p className="cardtxtabot">
                    Receive invaluable mentorship from
                    <br /> experts abroad to guide your <br /> academic and
                    personal growth
                  </p>
                </div>
                <div>
                  <div className="mentorcard-center2-about">
                    <img
                      className="widthimgabt"
                      src="../images/aboutthree.png"
                      alt=""
                    />
                    <p className="recomtxt">Get Recommendation</p>
                    <p className="cardtxtabot">
                      Unlock outstanding recommendations from international
                      educators to enrich
                      <br /> your academic journey
                    </p>{" "}
                  </div>{" "}
                </div>
                <div>
                  <div className="mentorcard-center3-about">
                    <img
                      className="widthimgabt"
                      src="../images/aboutfour.png"
                      alt=""
                    />
                    <p className="searcour">Search Course</p>
                    <p className="cardtxtabot">
                      Explore a wide range of courses and
                      <br /> find your ideal academic path with our
                      <br /> comprehensive search tool.
                    </p>{" "}
                  </div>{" "}
                </div>

                <div className="mentorcard-center1-about">
                  <img
                      src="../images/abouttwo.png"
                      alt=""
                  />
                  <p className="text-center-mentor-about">
                    Mentorship from Abroad
                  </p>
                  <p className="cardtxtabot">
                    Receive invaluable mentorship from
                    <br /> experts abroad to guide your <br /> academic and
                    personal growth
                  </p>
                </div>
                <div>
                  <div className="mentorcard-center2-about">
                    <img
                      className="widthimgabt"
                      src="../images/aboutthree.png"
                      alt=""
                    />
                    <p className="recomtxt">Get Recommendation</p>
                    <p className="cardtxtabot">
                      Unlock outstanding recommendations from <br />
                      international educators to enrich
                      <br /> your academic journey
                    </p>{" "}
                  </div>{" "}
                </div>
                <div>
                  <div className="mentorcard-center3-about">
                    <img
                      className="widthimgabt"
                      src="../images/aboutfour.png"
                      alt=""
                    />
                    <p className="searcour">Search Course</p>
                    <p className="cardtxtabot">
                      Explore a wide range of courses and
                      <br /> find your ideal academic path with our
                      <br /> comprehensive search tool.
                    </p>{" "}
                  </div>{" "}
                </div>
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Cardabwhy;

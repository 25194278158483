import React from "react";
import "./style.css";
import CollapsibleExample from "../../Components/navbar";
import Footer from "../../Components/footer";
import HeroIelts from "../../Components/heroielts";
import { Container } from "react-bootstrap";
import WhyUs from "../../Components/whystudents";
import AboutIelts from "../../Components/aboutielts";
import Academic from "../../Components/academic";
import Acceptability from "../../Components/acceptibility";
import VideoSection from "../../Components/videoSection";
import Faq from "../../Components/faq";
import ProgramHighlights from "../../Components/Program";

function Ielts() {
  return (
    <div>
      <div className="bg-clr">
        <Container>
          <CollapsibleExample />
          <HeroIelts />
          <WhyUs />
          <ProgramHighlights />
          <AboutIelts />
          <Academic />
          <Acceptability />
          <VideoSection />
          <Faq />
        </Container>
        <Footer />
      </div>
    </div>
  );
}

export default Ielts;

import React, { useState, useEffect } from "react";
import "./style.css";
import Global from "../../images/Huge Global.png";
import Line from "../../images/Vector 637.png";
import Air from "../../images/Vector (17).png";
import divLine from "../../images/div.png";
import Country from "../../images/SVG (3).png";
import Country1 from "../../images/SVG (4).png";
import Country2 from "../../images/SVG (5).png";
import Country3 from "../../images/SVG (7).png";
import Country4 from "../../images/SVG (8).png";
import Country5 from "../../images/SVG (9).png";
import Country6 from "../../images/SVG (10).png";
import Country7 from "../../images/SVG (11).png";
import Arrow from "../../images/span.jss107.png";
import Arrow1 from "../../images/div (1).png";
import { Col, Row, message } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const data = [
  {
    id: 1,
    img: <img src={Country} alt="" />,
    field: "Business and Management",
  },
  {
    id: 2,
    img: <img src={Country1} alt="" />,
    field: "Computer Science and IT",
  },

  {
    id: 3,
    img: <img src={Country2} alt="" />,
    field: "Engineering",
  },
  {
    id: 4,
    img: <img src={Country3} alt="" />,
    field: "Social Science",
  },
  {
    id: 5,
    img: <img src={Country4} alt="" />,
    field: "Architecture",
  },
  {
    id: 6,
    img: <img src={Country5} alt="" />,
    field: "Professional Studies",
  },
  {
    id: 7,
    img: <img src={Country6} alt="" />,
    field: "Hospitality and Tourism",
  },
  {
    id: 8,
    img: <img src={Country7} alt="" />,
    field: "Journalism and Media",
  },
  {
    id: 9,
    img: <img src={Country7} alt="" />,
    field: "Science",
  },
  {
    id: 10,
    img: <img src={Country7} alt="" />,
    field: "Sports",
  },
  {
    id: 11,
    img: <img src={Country7} alt="" />,
    field: "Fine Arts",
  },
  {
    id: 12,
    img: <img src={Country7} alt="" />,
    field: "Law",
  },
  {
    id: 13,
    img: <img src={Country7} alt="" />,
    field: "Education",
  },
  {
    id: 14,
    img: <img src={Country7} alt="" />,
    field: "Agriculture and Forestry",
  },
  {
    id: 15,
    img: <img src={Country7} alt="" />,
    field: "Mathematics",
  },
  {
    id: 16,
    img: <img src={Country7} alt="" />,
    field: "Medicine",
  },
];
function FinderTwo() {
  const navigate = useNavigate();
  const [selectedfield, setSelectedfield] = useState(null);
  const handleSmallDivCountryClick = (id) => {
    const selectedfieldData = data.find((item) => item.id === id);
    sessionStorage.setItem("selectedfield", selectedfieldData.field);
    setSelectedfield(selectedfieldData);
  };

  const handleNextButtonClick = () => {
    if (selectedfield) {
      console.log("Selected field:", selectedfield);

      setSelectedfield(null); // Clear the selected country after storing it in local storage
    } else {
      // Show a message indicating that a country needs to be selected
      alert("Please select a country before proceeding to the next step.");
    }
  };
  return (
    <div className="center-global">
      <Link to="/">
        <img className="logo-main" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <img src={Air} alt="" className="img-linee12" />
      <img src={Line} alt="" className="img-linee" />
      <div className="div-uni-count">
        <div>
          <h1>779</h1>
          <p style={{ fontWeight: "500" }}>universities</p>
        </div>
        <img src={divLine} alt="" className="hr-line" />
        <div>
          <h1>102220</h1>
          <p style={{ fontWeight: "500" }}>courses</p>
        </div>
      </div>
      <img src={Global} alt="" className="img-linee" />
      <div>
        <div className="bg-token-div">
          <div className="div-text-div">
            <img
              src={Arrow1}
              alt=""
              className="im-size12"
              onClick={() => navigate(-1)}
            />
            <p className="where-text12">
              what is your preferred area of study?
            </p>
          </div>

          <Row justify="center" className="width-divvv">
            {data.map((item) => (
              <Col xl={6}>
                <div className="center-coll-country">
                  <div>
                    <div
                      className={`small-div-country45 ${
                        selectedfield && selectedfield.id === item.id
                          ? "border-highlight1"
                          : ""
                      }`}
                      onClick={() => handleSmallDivCountryClick(item.id)}
                    >
                      <div>{item.img}</div>
                      <div>{item.field}</div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Link to="/finderThree" style={{ textDecoration: "none" }}>
            <button
              className={`btn-next ${selectedfield ? "" : "btn-next:disabled"}`}
              disabled={!selectedfield}
              onClick={handleNextButtonClick}
            >
              Next <img src={Arrow} alt="" className="im-size" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FinderTwo;

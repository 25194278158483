import React from "react";
import { Button, Col, Row, Form, Input, Select, Checkbox } from "antd";
import Ring1 from "../../images/Group 25.png";
import ErrorImage from "../../images/Ilustration.png";
import Arrow from "../../images/Icon.png";
import "./style.css";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div>
      <img src={Ring1} alt="" className="contct-logo-side-div" />
      <button className="btn-sign-up">Sign Up</button>
      <div className="padding-whole-div12">
        <div>
          <div>
            <Row justify="center">
              <Col xl={12} lg={12} md={12} xs={24}>
                <div className="contact-col12">
                  <div>
                    <h1 className="opp-text">Oops.... </h1>
                    <h2 className="page-not ">Page not found </h2>
                    <p className="exit-text">
                      This Page doesn`t exist or was removed!
                      <br />
                      We suggest you back to home.
                    </p>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <button className="btn-form1">
                        <img src={Arrow} alt="" /> Back to home
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24}>
                <div className="contact-col">
                  <div>
                    <img src={ErrorImage} alt="" className="img-height" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;

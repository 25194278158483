import React from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import Cardabwhy from "./cards";

function WhyAboutUs() {
  return (
    <div>
      <Container>
        <div className="noth">
          {" "}
          <p className="frsttextuii">Why Us</p>
          <p className="usabouttxt">
            Experience the world like never before with our transformative Study
            <br />
            abroad programs tailored to your unique interests. Join our global
            <br />
            network of learners and immerse yourself in diverse cultures for an
            <br />
            unforgettable adventure in education.
          </p>
        </div>
        <Cardabwhy />
      </Container>
    </div>
  );
}

export default WhyAboutUs;

import React from "react";
import "./style.css";
import { Button, Card, Col, Row } from "antd";

function AboutIelts() {
  return (
    <div>
      <p className="abietxt">About IELTS</p>
      <div className="divabo">
        <Row className="rowiel">
          <Col>
            <div>
              <p className="whtietext">What Is IELTS </p>
              <p className="nice-lorem">
                The International English Language
                <br /> Testing System (IELTS) is a standardized
                <br /> test used to assess the English language
                <br />
                proficiency of individuals who
                <br /> wish to study or work in
                <br />
                English-speaking countries.
                <br /> Recognized by over 10,000 organizations
                <br /> worldwide, IELTS is one of the most widely
                <br />
                accepted English language
                <br /> proficiency tests.
              </p>
              <div className="twrs">
                <button className="buseeall">
                  SEE ALL
                  <img
                    className="imgiel"
                    alt="abc"
                    src="../images/rightalign.svg"
                  />
                </button>
              </div>
            </div>{" "}
          </Col>
          <Col>
            {" "}
            <img
              className="ielimg"
              alt="abc"
              src="../images/ieltsbig2.png"
            />
          </Col>
        </Row>
      </div>
      <p className="abietxt">Types of IELTS</p>
      <Row justify={"space-evenly"}>
        <Col>
          <Card className="cardbcj">
            <div className="cardieltsss">
              <img alt="abc" src="../images/line.svg" />
              <div>
                <p className="acdie">Academic</p>
                <p className="scniet">
                  The IELTS Academic test is an essential gateway for
                  individuals
                  <br /> aspiring to pursue higher education in English-speaking
                  countries.
                  <br /> Recognized and accepted by thousands of universities
                  <br /> and educational institutions worldwide. We're devoted
                  to
                  <br /> helping you reach your educational goals, providing the
                  best advice
                  <br /> and materials for acing IELTS and getting into your
                  ideal college.
                </p>{" "}
              </div>
            </div>
          </Card>
        </Col>
        <Col>
          {" "}
          <Card className="cardbcj">
            <div className="cardieltsss">
              <img alt="abc" src="../images/line.svg" />
              <div>
                <p className="acdie">General</p>
                <p className="scniet">
                  The IELTS General Training test is your gateway to new
                  opportunities in English-speaking countries. Whether you dream
                  of working abroad, seeking international training programs, or
                  migrating to a new land, it is designed to assess your
                  practical language skills in everyday contexts. At The Student
                  Helpline, we offer IELTS General Training to empower you with
                  the language proficiency required to achieve your global
                  aspirations.
                </p>
              </div>
            </div>{" "}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AboutIelts;

import React from "react";
import "./style.css";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Name1 from "../../images/img (1).png";
import Name2 from "../../images/img (2).png";
import Name3 from "../../images/img (3).png";
import Name4 from "../../images/img (4).png";
import Name5 from "../../images/img (5).png";
import Name6 from "../../images/img (6).png";
import Name7 from "../../images/img (7).png";
import Name8 from "../../images/img (8).png";
import Name9 from "../../images/img (9).png";
import Name10 from "../../images/img (10).png";
import Name11 from "../../images/img (11).png";
import Name12 from "../../images/img (12).png";
const handleDragStart = (e) => e.preventDefault();
// const items = [
//   <div className="card-center" onDragStart={handleDragStart}>
//     <img src={Name1} alt="" />
//   </div>,
//   <div className="card-center1" onDragStart={handleDragStart}>
//     <img src={Name2} alt="" />
//   </div>,
//   <div className="card-center2" onDragStart={handleDragStart}>
//     <img src={Name3} alt="" />
//   </div>,
//   <div className="card-center1" onDragStart={handleDragStart}>
//     <img src={Name4} alt="" />
//   </div>,
//   <div className="card-center3" onDragStart={handleDragStart}>
//     <img src={Name5} alt="" />
//   </div>,
//   <div className="card-center4" onDragStart={handleDragStart}>
//     <img src={Name6} alt="" />
//   </div>,
//   <div className="card-center5" onDragStart={handleDragStart}>
//     <img src={Name7} alt="" />
//   </div>,
//   <div className="card-center6" onDragStart={handleDragStart}>
//     <img src={Name8} alt="" />
//   </div>,
//   <div className="card-center2" onDragStart={handleDragStart}>
//     <img src={Name9} alt="" />
//   </div>,
//   <div className="card-center7" onDragStart={handleDragStart}>
//     <img src={Name10} alt="" />
//   </div>,
//   <div className="card-center8" onDragStart={handleDragStart}>
//     <img src={Name11} alt="" />
//   </div>,
//   <div className="card-center3" onDragStart={handleDragStart}>
//     <img src={Name12} alt="" />
//   </div>,
// ];
// const responsive = {
//   375: { items: 1 },
//   425: { items: 2 },
//   568: { items: 3 },
//   1024: { items: 4 },
//   1440: { items: 4 },
//   2560: { items: 4 },
// };
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Name() {
  return (
    <div>
      <div className="center-cards">
        <div className="width-card">
          {/* <AliceCarousel
            items={items}
            disableDotsControls
            responsive={responsive}
            autoPlay
            animationDuration={5000}
            mouseTracking
          /> */}
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            transitionDuration={500}
          >
            <div className="card-center">
              <img src={Name1} alt="" />
            </div>
            <div className="card-center1">
              <img src={Name2} alt="" />
            </div>
            <div className="card-center2">
              <img src={Name3} alt="" />
            </div>
            <div className="card-center1">
              <img src={Name4} alt="" />
            </div>
            <div className="card-center3">
              <img src={Name5} alt="" />
            </div>
            <div className="card-center4">
              <img src={Name6} alt="" />
            </div>
            <div className="card-center5">
              <img src={Name7} alt="" />
            </div>
            <div className="card-center6">
              <img src={Name8} alt="" />
            </div>
            <div className="card-center2">
              <img src={Name9} alt="" />
            </div>
            <div className="card-center7">
              <img src={Name10} alt="" />
            </div>
            <div className="card-center8">
              <img src={Name11} alt="" />
            </div>
            <div className="card-center3">
              <img src={Name12} alt="" />
            </div>
          </Carousel>
          ;
        </div>
      </div>
    </div>
  );
}

export default Name;

import React from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import { Button, Col, Row, Avatar, Card, Form, Input } from "antd";
import BlockCard from "../../images/Rectangle 2749.png";
import BlockCard1 from "../../images/Rectangle 9561.png";
import BlockCard2 from "../../images/Rectangle 9560.png";
import TableOfContents from "../tableofcontent/TableofContent";

const data = [
  {
    id: 1,
    monthly: "234",
    linking: "6435",
    Tweeets: "123",
  },
];
const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

function ArticleBody() {
  return (
    <div className="mainclass">
      {/* <Container> */}
      <Row className="alcont">
        <Col className="allce">
          <TableOfContents />
        </Col>
        <Col className="stick">
          <div>
            <p className="text37">
              37 Best Content Creation Tools & Software in 2023
            </p>
            <br />
            <div className="buttuppub">
              <button className="buttonpubli">Published by Artem Minaev</button>
              <button className="buttonupdated">
                Updated: April 11th, 2023
              </button>
            </div>
            <div>
              <img
                className="imagesize"
                alt="abc"
                src="../images/cardblogmain.png"
              />
              <div>
                <p className="thereare">
                  There are many variations of passages of Lorem Ipsum
                  available, but
                  <br /> the majority have suffered alteration in some form, by
                  injected humour,
                  <br /> or randomised words which don't look even slightly
                  believable. If you are
                  <br /> going to use a passage of Lorem Ipsum, you need to be
                  sure there isn't
                  <br />
                  anything embarrassing hidden in the middle of text.
                </p>
                <p className="thereare">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem
                  <br /> accusantium doloremque laudantium, totam rem aperiam,
                  eaque ipsa
                  <br /> quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta
                  <br /> sunt explicabo. Nemo enim ipsam voluptatem quia{" "}
                </p>
                <br />
                <br />
                <p className="therearee">The standard Lorem Ipsum passage</p>
                <p className="thereare">
                  luptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni
                  <br /> dolores eos qui ratione voluptatem sequi nesciunt.
                  Neque porro
                  <br /> quisquam est, qui dolorem ipsum quia dolor sit amet,
                  consectetur,
                  <br /> adipisci velit, sed quia non numquam eius modi tempora
                  incidunt ut
                  <br /> labore et dolore magnam aliquam quaerat voluptatem. Ut
                  enim ad
                  <br /> minima veniam, quis nostrum exercitationem ullam
                  corporis suscipit
                  <br />
                  laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis
                  autem vel
                  <br /> eum iure reprehenderit qui in ea voluptate velit esse
                  quam nihil
                  <br /> molestiae consequatur, vel illum qui dolorem eum fugiat
                  quo voluptas
                  <br /> nulla pariatur?"
                </p>
              </div>
              <div>
                <br />
                <img
                  className="personsimg"
                  alt="abc"
                  src="../images/persons.png"
                />
              </div>
              <br />
              <p className="thereare">
                luptas sit aspernatur aut odit aut fugit, sed quia consequuntur
                magni
                <br /> dolores eos qui ratione voluptatem sequi nesciunt. Neque
                porro quisquam
                <br /> est, qui dolorem ipsum quia dolor sit amet, consectetur,
                adipisci velit, sed
                <br /> quia non numquam eius modi tempora incidunt ut labore et
                dolore
                <br /> magnam aliquam quaerat voluptatem. Ut enim ad minima
                veniam, quis
                <br />
                nostrum exercitationem ullam corporis suscipit laboriosam, nisi
                ut aliquid
                <br /> ex ea commodi consequatur? Quis autem vel eum iure
                reprehenderit qui in
                <br /> ea voluptate velit esse quam nihil molestiae consequatur,
                vel illum qui
                <br /> dolorem eum fugiat quo voluptas nulla pariatur?"
              </p>
            </div>
            <div>
              <br />
              <img
                className="personsimg"
                alt="abc"
                src="../images/personsec.png"
              />
              <img
                className="abcimg"
                src="../images/playbutton.png"
                alt="abc"
              />
            </div>{" "}
            <br />
            <p className="thereare">
              luptas sit aspernatur aut odit aut fugit, sed quia consequuntur
              magni
              <br /> dolores eos qui ratione voluptatem sequi nesciunt. Neque
              porro quisquam
              <br /> est, qui dolorem ipsum quia dolor sit amet, consectetur,
              adipisci velit, sed
              <br /> quia non numquam eius modi tempora incidunt ut labore et
              dolore
              <br /> magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis
              <br /> nostrum exercitationem ullam corporis suscipit laboriosam,
              nisi ut aliquid
              <br /> ex ea commodi consequatur? Quis autem vel eum iure
              reprehenderit qui in
              <br /> ea voluptate velit esse quam nihil molestiae consequatur,
              vel illum qui
              <br /> dolorem eum fugiat quo voluptas nulla pariatur?"
            </p>
          </div>
        </Col>
        <Col>
          <div className="thirdcolu">
            <div className="centerthird">
              <div className="avatarcenter">
                {" "}
                <Avatar
                  style={{ width: "5rem", height: "5rem" }}
                  src={<img src="../images/avatar.png" alt="avatar" />}
                />
              </div>
              <br />
              <p className="textjamal">Jamal kamal</p>
              <p className="textlong">
                It is a long established fact that a reader
                <br /> will be distracted by the readable content <br />
                of a page when looking at its layout.
              </p>
              <div className="imagee">
                <img alt="abc" src="../images/face.png" />
                <img alt="abc" src="../images/twi.png" />
                <img alt="abc" src="../images/link.png" />
              </div>
              <hr></hr>
              <p style={{ textAlign: "left" }} className="motylafz">
                Article Status
              </p>
              {data.map((item, index) => (
                <div key={index} className="alltxts">
                  <div className="threething">
                    <p>Monthly Traffic</p>
                    <p>{item.monthly}</p>
                  </div>
                  <div className="threething">
                    <p>Linking website</p>
                    <p>{item.linking}</p>
                  </div>
                  <div className="threething">
                    <p>Monthly Traffic</p>
                    <p>{item.Tweeets}</p>
                  </div>
                </div>
              ))}
              <p style={{ textAlign: "left" }} className="motylafz">
                Get Best Student helpine blog
              </p>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Your email"
                    className="iniputsize"
                  />
                </Form.Item>
                <Form.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button className="btn-suvbsss" htmlType="submit">
                    Subscribe
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      {/* </Container> */}
      <Container>
        {" "}
        <Container className="huu">
          {" "}
          <p className="latestarticle">Latest Article</p>
        </Container>
        <div>
          <Row justify="space-evenly">
            <Col xl={7} xxl={4} lg={8} md={10}>
              <div className="center-block">
                <div>
                  <Card
                    hoverable
                    style={{
                      width: 300,
                      height: 400,
                      marginTop: ".5rem",
                      marginBottom: ".5rem",
                    }}
                    cover={
                      <img
                        alt="example"
                        src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/shorter-degree-programs-in-uk.webp"
                      />
                    }
                  >
                    <div>
                      <p className="text-date">29 Jul 2023</p>
                      <p className="text-being">
                        The Benefits of the UK’s Shorter Degree Programs
                      </p>
                      <p className="text-product">
                        What's the best part? You're about to learn how
                        innovative programs like these can help you advance your
                        career while saving tim ...
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
            <Col xl={7} xxl={4} lg={8} md={10}>
              <div className="center-block">
                <div>
                  <Card
                    hoverable
                    style={{
                      width: 300,
                      height: 400,
                      marginTop: ".5rem",
                      marginBottom: ".5rem",
                    }}
                    cover={
                      <img
                        alt="example"
                        src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/countries-to-study-in-europe.webp"
                      />
                    }
                  >
                    <div>
                      <p className="text-date">29 Jul 2023</p>
                      <p className="text-being">
                        Affordable Countries to Study Abroad in Europe
                      </p>
                      <p className="text-product">
                        Be Prepared to be amazed by the possibilities. You can
                        get a world-class edu ...
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
            <Col xl={7} xxl={4} lg={8} md={10}>
              <div className="center-block">
                <div>
                  <Card
                    hoverable
                    style={{
                      width: 300,
                      height: 400,
                      marginTop: ".5rem",
                      marginBottom: ".5rem",
                    }}
                    cover={
                      <img
                        alt="example"
                        src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/10-ways-to-studying-in-abroad.webp"
                      />
                    }
                  >
                    <div>
                      <p className="text-date"> 29 July 2022</p>
                      <p className="text-being">10 Ways to Study Abroad</p>
                      <p className="text-product">
                        Students in high school and college are more eager to
                        study abroad than ever ...
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
          <div className="butncent">
            {" "}
            <button className="btn-seealll">
              See More Article{" "}
              <img src="../images/SVG.png" alt="" className="size" />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ArticleBody;

import React from "react";
import "./style.css";
import Arrow from "../../images/span.jss107.png";
import { Container } from "react-bootstrap";
import { Row, Col, Avatar, Card } from "antd";
import BlockCard from "../../images/Rectangle 2749.png";
import BlockCard1 from "../../images/Rectangle 9561.png";
import BlockCard2 from "../../images/Rectangle 9560.png";
import { Link } from "react-router-dom";

const { Meta } = Card;
function Block() {
  return (
    <div>
      <Container>
        <div className="div-flex-btn">
          <div>
            <p className="text-our ">Our Latest Blog</p>
            <p className="text-ts">hear from our students</p>
          </div>
          <Link to="/blogPage" style={{ textDecoration: "none" }}>
            <button className="btn-seeall">
              See all <img src={Arrow} alt="" className="im-size" />
            </button>
          </Link>
        </div>
      </Container>
      <Row justify="center">
        <Col xl={7} xxl={4} lg={8} md={10}>
          <div className="center-block">
            <div>
              <Link to="/blogPage" style={{ textDecoration: "none" }}>
                <Card
                  hoverable
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                  className="card-size"
                  cover={
                    <img
                      alt="example"
                      src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/shorter-degree-programs-in-uk.webp"
                    />
                  }
                >
                  <div>
                    <p className="text-date">29 Jul 2023</p>
                    <p className="text-being">
                      The Benefits of the UK’s Shorter Degree Programs
                    </p>
                    <p className="text-product">
                      What's the best part? You're about to learn how innovative
                      programs like these can help you advance your career while
                      saving tim ...
                    </p>
                  </div>
                </Card>
              </Link>
            </div>
          </div>
        </Col>
        <Col xl={7} xxl={4} lg={8} md={10}>
          <div className="center-block">
            <div>
              <Link to="/blogPage" style={{ textDecoration: "none" }}>
                <Card
                  hoverable
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                  className="card-size"
                  cover={
                    <img
                      alt="example"
                      src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/countries-to-study-in-europe.webp"
                    />
                  }
                >
                  <div>
                    <p className="text-date">29 Jul 2023</p>
                    <p className="text-being">
                      Affordable Countries to Study Abroad in Europe
                    </p>
                    <p className="text-product">
                      Be Prepared to be amazed by the possibilities. You can get
                      a world-class edu ...
                    </p>
                  </div>
                </Card>
              </Link>
            </div>
          </div>
        </Col>
        <Col xl={7} xxl={4} lg={8} md={10}>
          <div className="center-block">
            <div>
              <Link to="/blogPage" style={{ textDecoration: "none" }}>
                <Card
                  hoverable
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                  className="card-size"
                  cover={
                    <img
                      alt="example"
                      src="https://thestudenthelpline.co.in/admin_panel/assets/images/blog/10-ways-to-studying-in-abroad.webp"
                    />
                  }
                >
                  <div>
                    <p className="text-date"> 29 July 2022</p>
                    <p className="text-being">10 Ways to Study Abroad</p>
                    <p className="text-product">
                      Students in high school and college are more eager to
                      study abroad than ever ...
                    </p>
                  </div>
                </Card>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Block;

import React, { useState } from "react";
import "./style.css";
import Global from "../../images/Huge Global.png";
import Arrow from "../../images/div (1).png";
import Country from "../../images/image 18 (1).png";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
const { Option } = Select;
function FinderEight() {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 120,
          background: "white",
          borderRadius: ".5rem",
        }}
      >
        <Option value="91">
          {" "}
          <img src={Country} alt="" />
          +91
        </Option>
        <Option value="92">
          {" "}
          <img src={Country} alt="" />
          +92
        </Option>
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <Link to="/">
        <img className="logoabs" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <div className="center-global">
        <img src={Global} alt="" className="img-linee" />
        <br />
        <br />
        <div>
          <div className="bg-token-div">
            <div className="div-text-div">
              <p className="where-text">Let's get started</p>
            </div>
            <div>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input addonBefore={prefixSelector} className="input-div" />
              </Form.Item>
            </div>
            {/* <Link to="/finderFour" style={{ textDecoration: "none" }}> */}
            <button className="btn-next12 ">
              Continue <img src={Arrow} alt="" className="im-size" />
            </button>
            {/* </Link> */}
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default FinderEight;

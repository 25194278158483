import React, { useState, useEffect } from "react";
import "./style.css";
import Global from "../../images/Huge Global.png";
import Line from "../../images/Vector 637.png";
import Air from "../../images/Vector (18).png";
import divLine from "../../images/div.png";
import Country6 from "../../images/SVG (16).png";
import Country7 from "../../images/SVG (17).png";
import Country8 from "../../images/SVG (18).png";
import Country9 from "../../images/SVG (15).png";
import Arrow from "../../images/div (1).png";
import { Col, InputNumber, Row, Slider, Space, message } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    img: <img src={Country6} alt="" style={{ marginRight: ".5rem" }} />,
    test1: "TOEFL",
  },
  {
    id: 2,
    img: <img src={Country7} alt="" style={{ marginRight: ".5rem" }} />,
    test1: "IELTS",
  },
  {
    id: 3,
    img: <img src={Country8} alt="" style={{ marginRight: ".5rem" }} />,
    test1: "PTE",
  },
  {
    id: 4,
    img: <img src={Country9} alt="" style={{ marginRight: ".5rem" }} />,
    test1: "None",
  },
];
function FinderFive() {
  const navigate = useNavigate();

  const [inputValues, setInputValues] = useState(1);
  const onChange = (newValue) => {
    setInputValues(newValue);
  };
  const [selectedtest1, setSelectedtest1] = useState(null);

  const handleSmallDivCountryClick = (id) => {
    const selectedtest1Data = data.find((item) => item.id === id);
    sessionStorage.setItem("selectedtest1", selectedtest1Data.test1);
    setSelectedtest1(selectedtest1Data);
  };

  const handleNextButtonClick = () => {
    if (selectedtest1) {
      console.log("selectedtest1:", selectedtest1);

      setSelectedtest1(null); // Clear the selected country after storing it in local storage
    } else {
      // Show a message indicating that a country needs to be selected
      alert("Please select a country before proceeding to the next step.");
    }
  };
  useEffect(() => {
    // Save the inputValues in session storage whenever it changes
    sessionStorage.setItem("inputValues", inputValues.toString());
  }, [inputValues]);

  // Retrieve the stored inputValues from session storage on component mount
  useEffect(() => {
    const storedInputValue = sessionStorage.getItem("inputValues");
    // Convert the stored value back to a number using Number() function
    if (storedInputValue !== null) {
      setInputValues(Number(storedInputValue));
    }
  }, []);
  return (
    <div className="center-global">
      <Link to="/">
        <img className="logo-main" alt="abc" src="../images/studentlogo.png" />
      </Link>
      <img src={Air} alt="" className="img-linee123489" />
      <img src={Line} alt="" className="img-linee" />
      <div className="div-uni-count">
        <div>
          <h1>150</h1>
          <p style={{ fontWeight: "500" }}>universities</p>
        </div>
        <img src={divLine} alt="" className="hr-line" />
        <div>
          <h1>1725</h1>
          <p style={{ fontWeight: "500" }}>courses</p>
        </div>
      </div>
      <img src={Global} alt="" className="img-linee" />
      <div>
        <div className="bg-token-div">
          <div className="div-text-div">
            <img
              src={Arrow}
              alt=""
              className="im-size12"
              onClick={() => navigate(-1)}
            />
            <p className="where-text">which english test did you take?</p>
          </div>
          <div className="div-btn-both1289">
            {data.map((item) => (
              <div
                className={`small-div-country1289  ${
                  selectedtest1 && selectedtest1.id === item.id
                    ? "border-highlight1"
                    : ""
                }`}
                onClick={() => handleSmallDivCountryClick(item.id)}
              >
                <div>{item.img}</div>
                {item.test1}
              </div>
            ))}
          </div>

          <div className="div-slide">
            <div>
              <p>select your score</p>
              <div className="silde-bg">
                <Slider
                  min={1}
                  max={10}
                  onChange={onChange}
                  value={typeof inputValues === "number" ? inputValues : 0}
                  className="slides-width89"
                />
              </div>
            </div>
            <InputNumber
              min={1}
              max={10}
              style={{
                margin: "0 16px",
                marginTop: "2rem",
              }}
              value={inputValues}
              onChange={onChange}
            />
          </div>
          <Link to="/finderSix" style={{ textDecoration: "none" }}>
            <button
              className={`btn-next ${selectedtest1 ? "" : "btn-next:disabled"}`}
              disabled={!selectedtest1}
              onClick={handleNextButtonClick}
            >
              Next <img src={Arrow} alt="" className="im-size" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FinderFive;

import React, { useRef, useState } from "react";
import "./style.css";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const handleDragStart = (e) => e.preventDefault();
// const items = [
//   <div onDragStart={handleDragStart}>
//     <div className="video-center">
//       <video
//         src="https://thestudenthelpline.co.in/assets/video/t2.webm"
//         type="video/mp4"
//         controls
//         className="video-size12"
//       />
//     </div>
//     <div className="div-bggg12">
//       <p>Kansha</p>
//       <p>
//         The Student Helpline comes highly recommended. Their comprehensive
//         support and guidance can make your global education dreams a reality
//       </p>
//     </div>
//   </div>,
//   <div onDragStart={handleDragStart}>
//     <div className="video-center">
//       <video
//         src="https://thestudenthelpline.co.in/assets/video/t3.webm"
//         type="video/mp4"
//         controls
//         className="video-size12"
//       />
//     </div>
//     <div className="div-bggg12">
//       <p>Ruchita</p>
//       <p>
//         The Student Helpline comes highly recommended. Their comprehensive
//         support and guidance can make your global education dreams a reality
//       </p>
//     </div>
//   </div>,
//   <div onDragStart={handleDragStart}>
//     <div className="video-center">
//       <video
//         src="https://thestudenthelpline.co.in/assets/video/t6.webm"
//         type="video/mp4"
//         controls
//         className="video-size12"
//       />
//     </div>
//     <div className="div-bggg12">
//       <p>Jatin</p>
//       <p>
//         With their extensive expertise and unwavering support, your journey to
//         international education will be smooth and rewarding
//       </p>
//     </div>
//   </div>,
//   <div onDragStart={handleDragStart}>
//     <div className="video-center">
//       <video
//         src="https://thestudenthelpline.co.in/assets/video/t2.webm"
//         type="video/mp4"
//         controls
//         className="video-size12"
//       />
//     </div>
//     <div className="div-bggg12">
//       <p>Nidhi</p>
//       <p>
//         I strongly endorse The Student Helpline for anyone considering studying
//         abroad
//       </p>
//     </div>
//   </div>,
// ];
// const responsive = {
//   375: { items: 1 },
//   425: { items: 1 },
//   568: { items: 2 },
//   1024: { items: 2 },
//   1440: { items: 3 },
//   2560: { items: 3 },
// };
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function VideoSection() {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  // Ref for the last video that triggered hover

  // Ref for the last video that triggered hover

  const [lastVideo, setLastVideo] = useState(null);

  const handleMouseEnter = (videoRef) => () => {
    if (lastVideo && lastVideo !== videoRef) {
      lastVideo.pause();
    }

    // Check if the video is ready for playback and not already playing
    if (videoRef.current.readyState >= 2 && videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        // Autoplay was prevented, we can handle it gracefully
        console.log("Autoplay prevented:", error);
      });
    }

    setLastVideo(videoRef.current);
  };

  const handleMouseLeave = (videoRef) => () => {
    videoRef.current.pause();
  };
  return (
    <div>
      <div className="div-texted12 ">
        <p className="test-text23">Meet Our Team</p>
        <h1 className="hear-text">Our Passionate Team</h1>
      </div>
      <VideoSection />
    </div>
  );
}

export default VideoSection;

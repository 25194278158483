import React from "react";
import "./style.css";
import CollapsibleExample from "../../Components/navbar";
import HeroSectionBlog from "../../Components/heroSectionBlog";
import ArticleBody from "../../Components/articlebody";
import Footer from "../../Components/footer";

function ArticleMain() {
  return (
    <div>
      <div className="bg-clr">
        <CollapsibleExample />
        <HeroSectionBlog />
        <ArticleBody />
        <Footer />
      </div>
    </div>
  );
}

export default ArticleMain;
